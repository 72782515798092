<template>
  <router-link :to="path" tag="div">
    <div class="quick-access-card" :style="colorCard">
      <div class="block-card">
        <svg
          width="41"
          height="41"
          viewBox="0 0 41 41"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style="float: right"
        >
          <rect
            x="0.726562"
            y="0.19043"
            width="40"
            height="40"
            rx="5"
            fill="white"
          />
          <path
            d="M30.7266 7.69043H10.7266C9.47656 7.69043 8.22656 8.81543 8.22656 10.1904V13.9529C8.22656 14.8529 8.76406 15.6279 9.47656 16.0654V30.1904C9.47656 31.5654 10.8516 32.6904 11.9766 32.6904H29.4766C30.6016 32.6904 31.9766 31.5654 31.9766 30.1904V16.0654C32.6891 15.6279 33.2266 14.8529 33.2266 13.9529V10.1904C33.2266 8.81543 31.9766 7.69043 30.7266 7.69043ZM29.4766 30.1904H11.9766V16.4404H29.4766V30.1904ZM30.7266 13.9404H10.7266V10.1904H30.7266V13.9404Z"
            :fill="color"
          />
          <path
            d="M16.9766 21.4404C16.9766 20.7501 17.5362 20.1904 18.2266 20.1904H23.2266C23.9169 20.1904 24.4766 20.7501 24.4766 21.4404C24.4766 22.1308 23.9169 22.6904 23.2266 22.6904H18.2266C17.5362 22.6904 16.9766 22.1308 16.9766 21.4404Z"
            :fill="color"
          />
        </svg>

        <v-icon>{{ icon }}</v-icon>

        <!-- <img style="float: right;" :src="'/assets/icon/' + icon" alt="product" /> -->
      </div>
      <div class="block-card" style="padding-top: 40px">
        <span class="title-quick-access-card block-card">{{ title }}</span>
        <span class="subtitle-quick-access-card block-card">{{
          subtitle
        }}</span>
      </div>
      <div class="circle"></div>
    </div>
  </router-link>
</template>

<script>
export default {
  name: "QuickAccess",
  props: {
    title: {
      type: String,
      default: "loaing",
    },
    subtitle: {
      type: Number,
      default: "loading",
    },
    icon: {
      type: String,
      default: "ico_product_quick_access.svg",
    },
    color: {
      type: String,
      default: "#F98404",
    },
    path: {
      type: String,
      default: "/dashboard",
    },
  },
  computed: {
    colorCard() {
      return {
        background: this.color,
      };
    },
  },
};
</script>

<style>
.icon {
  fill: #000;
}

.quick-access-card {
  width: auto;
  height: 160px;
  border-radius: 4px;
  background: #0f0f0f;
  padding-inline: 20px;
  padding-block: 20px;
  position: relative;
  overflow: hidden;
  z-index: 0;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.block-card {
  display: block;
}
.title-quick-access-card {
  font-family: "Manrope", sans-serif;
  font-size: 24px;
  font-weight: 600;
  color: #fff;
}
.subtitle-quick-access-card {
  font-family: "Manrope", sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #fff;
}

.circle {
  height: 160px;
  width: 160px;
  background-color: #fff;
  border-radius: 100%;
  position: absolute;
  right: -60px;
  top: -60px;
  z-index: 1;
  opacity: 15%;
  transition: all 0.3s ease-in-out;
}

.quick-access-card:hover .circle {
  -ms-transform: scale(5, 5);
  transform: scale(5, 5);
}

.quick-access-card:hover {
  box-shadow: 0 0 40px -10px rgba(#000, 0.4);
}
</style>