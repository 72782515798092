<template>
  <div>
    <v-alert dense type="info"> Selamat datang di Panel Admin Ngegame </v-alert>
    <v-row>
      <v-col>
        <quick-access
          title="User"
          v-bind:subtitle="statistics.user"
          color="#36b07d"
          icon="mdi-account"
          path="user" /></v-col
      ><v-col
        ><quick-access
          title="Tournament"
          v-bind:subtitle="statistics.tournament"
          color="#f8b32b"
          icon="mdi-tournament"
          path="tournament"
      /></v-col>
    </v-row>
    <v-row>
      <v-col
        ><quick-access
          title="Timeline"
          v-bind:subtitle="statistics.timeline"
          color="#2196f3"
          icon="mdi-timeline"
          path="timeline"
      /></v-col>
      <v-col
        ><quick-access
          title="Store"
          v-bind:subtitle="statistics.catalogs"
          color="#675CAD"
          icon="mdi-store"
          path="catalogs"
      /></v-col>
    </v-row>
    <v-row>
      <v-col>
        <quick-access
          title="Report"
          v-bind:subtitle="statistics.report"
          color="#FF4A4A"
          icon="mdi-alert"
          path="#"
      /></v-col>
    </v-row>
  </div>
</template>

<script>
import QuickAccess from "../components/Widget.vue";
import axios from "axios";

export default {
  name: "Home",

  components: {
    QuickAccess,
  },

  data() {
    return {
      statistics: [],
    };
  },

  mounted() {
    const AuthStr = "Bearer ".concat(this.$store.getters.getToken);

    axios
      .create({ withCredentials: false })
      .get(`${process.env.VUE_APP_API_URL}/v2/admin/statistic`, {
        headers: { Authorization: AuthStr },
      })
      .then((response) => {
        this.loading = false;

        this.statistics = response.data.result;
      })
      .catch((error) => {
        this.$toast.warning(error.response.data.message || "Unauthorized");
        this.loading = false;
      });
  },
};
</script>
